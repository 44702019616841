@font-face {
  font-family: 'vazir';
  src: url('../fonts/vazir.ttf') format('woff'),
    url('../fonts/vazir.ttf') format('truetype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: content-box;
  box-sizing: border-box;
  font: normal 15px 'vazir';
  line-height: 20px;
  text-decoration: none;
  outline: none;
}

html, body, #root, .app {
  height: 100%;
}

body {
  background: #e5effa;
}

.MuiCollapse-root {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translate(-50%, 0);
}

.app {
  display: flex;
}

.login {
  align-self: center;
  margin: 0 auto;
  background: #fff;
  min-width: 380px;
  max-width: 780px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.login h3 {
  color: #333;
  font-weight: bold;
  margin-bottom: 15px;
}

.inputLogin {
  border: 1px solid #ddd;
  border-radius: 8px;
  position: relative;
  width: 100%;
}

.inputLogin span {
  position: absolute;
  background: #fff;
  font-size: 10px;
  left: 10px;
  top: -6px;
  padding: 0 4px;
  display: block;
  line-height: 13px;
}

.inputLogin input {
  border: none;
  background: none;
  padding: 9px 15px;
  font-size: 14px;
  width: 100%;
}

.inputLogin>div {
  padding: 11px 15px 9px;
  font-size: 14px;
}

.submit {
  background: #007AFF;
  color: #fff;
  min-width: 100px;
  text-align: center;
  border-radius: 100px;
  padding: 8px 8px 6px;
  cursor: pointer;
}

.menu {
  width: 280px;
  padding: 30px;
  background: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  position: fixed;
  height: 100%;
  top: 0;
  display: flex;
}

.menuList {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 7px;
}

.menuList a {
  background: #f4f4f4;
  display: flex;
  width: 100%;
  padding: 9px 14px 7px;
  border-radius: 100px;
  gap: 7px;
  cursor: pointer;
  color: #333;
}

.menuList a.active {
  background: #007AFF;
  color: #fff;
}

.container {
  margin-left: 280px;
  padding: 50px;
  width: 100%;
}

.container h1 {
  font-size: 28px;
}

.tableData {
  margin-top: 35px;
  background: #fff;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  border-spacing: 0;
  overflow: hidden;
}

.tableData tr td {
  padding: 11px 12px 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 13px;
}

.tableData tr:nth-last-of-type(1) td {
  border-bottom: none;
}

.tableData tr td:nth-of-type(1) {
  border-left: none;
}

.tableData tr td.setting {
  padding: 0;
  text-align: center;
  vertical-align: center;
}

.tableData tr:nth-of-type(1) td {
  background: #333;
  color: #fff;
  font-weight: bold;
}

.MuiPagination-root {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  gap: 4px;
  flex-direction: row;
}

.MuiPagination-root .Mui-selected {
  background: #007AFF !important;
}

.setting span {
  display: inline-block;
  background: #ddd;
  border-radius: 100px;
  width: 21px;
  height: 21px;
  margin: 3px 2px 0;
  cursor: pointer;
}

.setting span svg {
  margin-top: 3px;
}

.setting .active {
  background: #30bb39;
  color: #fff;
}

.topButtons {
  display: flex;
  margin-top: 30px;
}

.topButtons span {
  display: flex;
  background: #007AFF;
  color: #fff;
  border-radius: 100px;
  padding: 10px 15px 7px;
  min-width: 100px;
  gap: 3px;
  cursor: pointer;
}

.MuiModal-root {
  display: flex;
  justify-content: center;
  align-items: center;
}

.alerts {
  z-index: 11111;
}

.loginAuto {
  width: 750px;
}

.tabs {
  display: flex;
  gap: 4px;
}

.tabs span {
  background: #ddd;
  border-radius: 8px;
  padding: 6px 12px 4px;
  cursor: pointer;
}

.tabs span.active {
  background: #007AFF;
  color: #fff;
}

.contentTab {
  width: 100%;
  max-height: 580px;
  overflow: scroll;
}

.contentTab .tableData {
  background: #f8f8f8;
  margin: 0;
  box-shadow: none;
}

.penEdit svg {
  transform: scale(0.7);
}

.group {
  display: flex;
  flex: 1;
  width: 100%;
  gap: 8px;
}

.inputLogin select {
  width: 100%;
  border: none;
  background: none;
  padding: 8px 8px 5px;
}

.topCoins {
  gap: 5px;
}

.topCoins span {
  justify-content: center;
}

.topCoins span.active {
  background: #20b32a;
}

.sendTo {
  background: #007AFF;
  color: #fff;
  border-radius: 100px;
  display: inline-block;
  font-size: 13px;
  padding: 3px 12px 1px;
  cursor: pointer;
  margin-right: 5px;
}

.changeAmount {
  cursor: pointer;
}

.changeAmount:hover {
  color: #007AFF;
}

.search {
  margin-top: 10px;
}

.search input {
  background: #fff;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 12px;
  padding: 8px 12px 6px;
  width: 300px;
}

.showEnergy {
  font-size: 13px;
  display: inline-block;
}

.groupLabel {
  display: flex;
  gap: 10;
  flex-wrap: wrap;
  margin-top: 4px;
}

.groupLabel section {
  display: flex;
  width: calc(100% / 3);
  font-size: 12px;
  gap: 5px;
  cursor: pointer;
}

.groupLabel section i {
  width: 16px;
  height: 16px;
  background: #ccc;
  border: none;
  border-radius: 4px;
}

.groupLabel section i.active {
  background: #007AFF;
}

.searchPro {
  display: flex;
  gap: 5px;
  margin-top: 20px;
}

.searchPro select, .searchPro input {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 4px 8px;
}

.searchPro div {
  background: #007AFF;
  color: #fff;
  border-radius: 4px;
  padding: 0 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.searchPro div:nth-of-type(2) {
  background: #30bb39;
}

.buttons {
  margin-top: 30px;
}

.buttons span {
  background: #f4ae23;
}

.allStat, .allStat * {
  font-size: 12px !important;
}

.titleStat {
  color: red;
  display: block;
  margin-top: 10px;
}

.changeEmail {
  display: block;
  font-size: 12px;
  color: #007AFF;
  cursor: pointer;
}

.sendEmail {
  color: #20b32a;
}

.inner span {
  display: block;
  text-align: center;
}

.inner span:nth-of-type(2) {
  color: #007AFF;
}
