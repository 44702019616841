@media (max-width: 600px) {
  .menu {
    width: 60px;
    padding: 10px;
  }

  .menuList a {
    padding: 9px 0 7px;
    font-size: 0;
    justify-content: center;
    align-items: center;
  }

  .container {
    margin-left: 50px;
    padding: 20px;
  }
}
